import {create} from 'zustand'

const useMainScreenModal = create ((set) => ({
    isOpen : false,

    //? Types of Modal open state
    locationModal : false,
    uploadKMLModal : false,
    generateDPR : false,
    nregaModal : false,
    changeLanguage : false,
    settlementInfo : false,

    //? For Holding Data
    locationData : null,
    nregaBody : null,
    assetData : null,
    assetType : null,

    //? For Handling Bug Report Modal
    BugReportModal : false,

    //* For opening and closing the Main Screen Modal
    onOpen : () => set((state) => ({isOpen : true})),
    onClose : () => set((state) => ({isOpen : false})),

    //* for location change Modal
    onSetState : (Data) => set((state) => ({locationData : Data})),
    onSetLocationModal : () => set((state) => ({locationModal : !state.locationModal})),

    //* For KML upload Modal
    onSetUploadKML : () => set((state) => ({uploadKMLModal : !state.uploadKMLModal})),

    //* For DPR generation Modal
    onSetGenerateDPR : () => set((state) => ({generateDPR : !state.generateDPR})),

    //* For Language change Modal
    onSetLanguage : () => set((state) => ({changeLanguage : !state.changeLanguage})),

    //* For NREGA Works Modal*/
    onSetNREGAWorks : () => set((state) => ({nregaModal : !state.nregaModal})),
    onSetNregaBody : (NregaBody) => set((state) => ({nregaBody : NregaBody})),

    //* For Asset Info Display
    onSetSettlementInfo : (assetInfo) => set((state) => ({assetData : assetInfo})),
    onSetAssetType : (assettype) => set((state) => ({assetType : assettype})),
    onSettlementToggle : () => set((state) => ({settlementInfo : !state.settlementInfo})),

    //* For Bug Report Modal
    onSetBugReportModal : () => set((state) => ({BugReportModal : !state.BugReportModal})),

}))

export default useMainScreenModal;