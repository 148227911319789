import React from "react";
import { useTranslation } from 'react-i18next';

const InfoModal = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    
    if (!isOpen) return null;

    return (
        <div style={infoModalStyle}>
            <div style={infoModalContentStyle}>
                <h2>{t("Information")}</h2>
                <p>{t("Step")} 1: {t("info_main_1")}</p>
                <p>{t("Step")} 2: {t("info_main_2")}</p>
                <p>{t("Step")} 3: {t("info_main_3")}</p>
                <h3>{t("GroundWater")}</h3>
                <p>{t("info_main_4")}</p>
                <h3>{t("Surface WaterBodies")}</h3>
                <p>{t("info_main_5")}</p>
                <h3>{t("Agri")}</h3>
                <p>{t("info_main_6")}</p>
                <h3>{t("Livelihood")}</h3>
                <p>{t("info_main_7")}</p>
                <div style={closeButtonContainerStyle}>
                    <button onClick={onClose} style={closeButtonStyle}>Close</button>
                </div>
            </div>
        </div>
    );
};

export default InfoModal;

const infoModalStyle = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(5px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '1000',
  };

const infoModalContentStyle = {
    width: '300px',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    textAlign: 'left',
};

const closeButtonContainerStyle = {
    textAlign: 'center',
    marginTop: '10px'
};

const closeButtonStyle = {
    backgroundColor: 'white',
    border: '2px solid #333',
    borderRadius: '5px',
    color: '#333',
    padding: '5px 10px',
    cursor: 'pointer',
    marginTop: '10px',
    transition: '0.3s all',
    '&:hover': {
        backgroundColor: '#f7f7f7',
    }
};

const legendGridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)', // Two equal-width columns
    gap: '10px 20px',
    alignItems: 'center',
    maxWidth: '100%',
};

const legendStyle = {
    padding: '10px 0',
};

const legendItemStyle = {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    maxWidth: '200px',
};

const colorPill = {
    width: '14px',
    height: '24px',
    borderRadius: '7px',
    marginRight: '5px',
};
