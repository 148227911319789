export const app_tour_state_main_screen =  [
{ target: ".footer-buttons", content: "To begin planning natural resource management work, go to these tabs.", disableBeacon: true },
{ target: ".t1", content: "Mark hamlets and resource ownership and usage under the social mapping screen.", disableBeacon: true },
{ target: ".t2", content: "Select for understanding groundwater stress at the micro-watershed level.", disableBeacon: true },
{ target: ".t3", content: "To check the condition of surface bodies like ponds and lakes over the years, select Surface Water Bodies.", disableBeacon: true},
{ target: ".t4", content: "To learn about the crops grown throughout the year, select Agri.", disableBeacon: true },
{ target: ".t6", content: "Under the NREGA Works tab, pick the NREGA assets you want (like irrigation, soil and water conservation, plantations) from the drop-down menu to see them on the map. Click on the map to get detailed information about the chosen asset.", disableBeacon: true },
{ target: ".t7", content: "The fly here button takes you to your current location.", disableBeacon: true},
{ target: ".t8", content: "The information button provides helpful information about the current screen.", disableBeacon: true},
];


export const app_tour_state_groundwater = [
  { target: ".t1", content: "By selecting the Analyze tab, the user can view  the % changes happening in the ground water levels on fortnightly basis in micro watershed over the course of each year, highlighting changes from one year to the next", disableBeacon: true },
  { target: ".t2", content: "Under “start planning” tab the user can start planning the recharge structure based on a color-coded CLART map showing recharge feasibility and user can  also find out  what kind of treatment is needed  for High ,Moderate and low  groundwater recharge structures and also  areas suitable for regeneration Note: Well depth tab need to selected" , disableBeacon: true},
  { target: ".t3", content: "To view the trends for a specific time period, select the corresponding range on the slider, like 2017 - 2022 or 2018 - 2023." , disableBeacon: true},
  { target: ".t4", content: "Please select the equity button to see the panchayats where the per capita expenditure has been less than the mean per capita expenditure in India.", disableBeacon: true },
  { target: ".t5", content: "Please select the well depth button  to see the Well depth of each microwatershed" },
  { target: ".t6", content: "This screen helps user track groundwater changes over the past five years and find areas in need of groundwater recharge structures.", disableBeacon: true },
  { target: ".t7", content: "The fly here button takes you to your current location" },
  { target: ".t8", content: "The information button provides helpful information about the current screen.", disableBeacon: true},
];

export const app_tour_state_surfacewaterbody = [
  
  { target: ".t1", content: "Under the NREGA Works tab, pick the NREGA assets you want (like irrigation, soil and water conservation, plantations) from the drop-down menu to see them on the map. Click on the map to get detailed information about the chosen asset.", disableBeacon: true },
  { target: ".t4", content: "Please select the equity tab to see the panchayats where the per capita expenditure has been less than the mean per capita expenditure in India.", disableBeacon: true },
  { target: ".t5", content: "Please select Analyze tab to see health indicator of water bodies in terms of their % area having water" , disableBeacon: true},
  { target: ".t6", content: "By selecting 'Propose Maintenance' tab user can select the  type of maintenance on existing water body." , disableBeacon: true},
  { target: ".t7", content: "The information button provides helpful information about the current screen.", disableBeacon: true},
];

export const app_tour_state_agri = [
  
  { target: ".t1", content: "By selecting the 'Analyze tab' the user can examine changes in the cropping intensity. User can see if  single cropping areas may have transitioned to double or triple cropping and vice versa. This helps user to  understand drought incidents in the area, allowing user to take appropriate actions based on observations." , disableBeacon: true},
  { target: ".t2", content: "Select the year on slider to view  trend for that year", disableBeacon: true },
  { target: ".t3", content: "Please select 'LULC' tab to visualize areas doing single cropping (kharif, late kharif), double cropping (kharif and rabi), and triple cropping (kharif, rabi, zaid). The user can click at desired point on the map to analyze and propose new irrigation work", disableBeacon: true },
  { target: ".t4", content: "The user can start planning to propose new water harvesting structures, soil treatment through land re-levelling, and other initiatives, based on issues observed in the data.", disableBeacon: true },
  { target: ".t5", content: "Please select 'Propose New Irrigation work' tab  to select the type of work  by submitting the dully filled form.", disableBeacon: true },
  { target: ".t6", content: "The information button provides helpful information about the current screen.", disableBeacon: true},
];

export const app_tour_social_mapping = [
  
  { target: ".t1", content: "Click on the map to mark a hamlet and then press the add-hamlet button to provide information about the hamlet" , disableBeacon: true },
  { target: ".t2", content: "To select a hamlet for which you want to map resource usage, do a long press on a hamlet thumbnail", disableBeacon: true },
  { target: ".t3", content: "Press here to start mapping resources being used by the selected hamlet" , disableBeacon: true},
  { target: ".t4", content: "Press here to provide more information about NREGA utilization by this hamlet", disableBeacon: true },
  { target: ".t6", content: "The information button provides helpful information about the current screen", disableBeacon: true},
  { target: ".t5", content: "The fly here button takes you to your current location", disableBeacon: true},

];

export const test_steps = [
  
  {
    element: '#t1',
    intro: 'step 2',
  },
];