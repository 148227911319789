import styles from "../agri/AgriScreen.module.css";

import {
  Line,
  LineChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Dot,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React, { Component, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { useNavigate } from "react-router-dom";
import * as util from "../helper/utils";

import Button from "../components/Button";
import useAnalyzeModal from "../hooks/useAnalyzeModal";
import Modal from "../components/Modal";
import useOdkModal from "../hooks/useOdkModal";

import { useTranslation } from "react-i18next";

const theme = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        thumb: {
          color: "#E59866",
        },
        track: {
          color: "#DC7633",
        },
        rail: {
          color: "#FBEEE6",
        },
        markLabel: {
          color: "black",
          fontSize: "11px",
          fontWeight: "bold",
        },
      },
    },
  },
});

const AgricultureModal = () => {
  const isOpen = useAnalyzeModal((state) => state.isOpen);
  const onClose = useAnalyzeModal((state) => state.onClose);

  const onOpenOdk = useOdkModal((state) => state.onOpen);
  const onSetState = useOdkModal((state) => state.onSetState);

  const featureData = useAnalyzeModal((state) => state.feature);

  const { t } = useTranslation();

  const drought_data = {};
  const years = [2016, 2017, 2018, 2019, 2020, 2021, 2022];

  years.forEach((year) => {
    const key = `drlb_${year}`;
    const data = sessionStorage.getItem(key);

    if (data) {
      const matches = String(data).match(/\d+/g);
      drought_data[year] = matches ? matches.map(Number) : [];
    } else {
      drought_data[year] = [];
      console.log(`Data for ${year} is not available`);
    }
  });

  const {
    2016: arr_drought_2016 = [],
    2017: arr_drought_2017 = [],
    2018: arr_drought_2018 = [],
    2019: arr_drought_2019 = [],
    2020: arr_drought_2020 = [],
    2021: arr_drought_2021 = [],
    2022: arr_drought_2022 = [],
  } = drought_data;

  // drought frequency
  // array here and [0, 1, 2, 3]
  // const arr_drought_2016 = sessionStorage.getItem("drlb_2016")
  //   ? String(sessionStorage.getItem("drlb_2016")).match(/\d+/g).map(Number)
  //   : [];
  // const arr_drought_2017 = sessionStorage.getItem("drlb_2017")
  //   ? String(sessionStorage.getItem("drlb_2017")).match(/\d+/g).map(Number)
  //   : [];
  // const arr_drought_2018 = sessionStorage.getItem("drlb_2018")
  //   ? String(sessionStorage.getItem("drlb_2018")).match(/\d+/g).map(Number)
  //   : [];
  // const arr_drought_2019 = sessionStorage.getItem("drlb_2019")
  //   ? String(sessionStorage.getItem("drlb_2019")).match(/\d+/g).map(Number)
  //   : [];
  // const arr_drought_2020 = sessionStorage.getItem("drlb_2020")
  //   ? String(sessionStorage.getItem("drlb_2020")).match(/\d+/g).map(Number)
  //   : [];
  // const arr_drought_2021 = sessionStorage.getItem("drlb_2021")
  //   ? String(sessionStorage.getItem("drlb_2021")).match(/\d+/g).map(Number)
  //   : [];
  // const arr_drought_2022 = sessionStorage.getItem("drlb_2022")
  //   ? String(sessionStorage.getItem("drlb_2022")).match(/\d+/g).map(Number)
  //   : [];

  function countValues(arr) {
    let count_0 = 0;
    let count_1 = 0;
    let count_2 = 0;
    let count_3 = 0;

    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === 0) {
        count_0++;
      } else if (arr[i] === 1) {
        count_1++;
      } else if (arr[i] === 2) {
        count_2++;
      } else if (arr[i] === 3) {
        count_3++;
      }
    }

    return { count_0, count_1, count_2, count_3 };
  }

  const counts_2016 = countValues(arr_drought_2016);
  const counts_2017 = countValues(arr_drought_2017);
  const counts_2018 = countValues(arr_drought_2018);
  const counts_2019 = countValues(arr_drought_2019);
  const counts_2020 = countValues(arr_drought_2020);
  const counts_2021 = countValues(arr_drought_2021);
  const counts_2022 = countValues(arr_drought_2022);
  // console.log(counts_2022)
  const arraySize = arr_drought_2016.length; // Assuming all arrays have the same size

  const no_drought_2016 = counts_2016.count_0;
  const mild_2016 = counts_2016.count_1;
  const moderate_2016 = counts_2016.count_2;
  const severe_2016 = counts_2016.count_3;

  const no_drought_2017 = counts_2017.count_0;
  const mild_2017 = counts_2017.count_1;
  const moderate_2017 = counts_2017.count_2;
  const severe_2017 = counts_2017.count_3;

  const no_drought_2018 = counts_2018.count_0;
  const mild_2018 = counts_2018.count_1;
  const moderate_2018 = counts_2018.count_2;
  const severe_2018 = counts_2018.count_3;

  const no_drought_2019 = counts_2019.count_0;
  const mild_2019 = counts_2019.count_1;
  const moderate_2019 = counts_2019.count_2;
  const severe_2019 = counts_2019.count_3;

  const no_drought_2020 = counts_2020.count_0;
  const mild_2020 = counts_2020.count_1;
  const moderate_2020 = counts_2020.count_2;
  const severe_2020 = counts_2020.count_3;

  const no_drought_2021 = counts_2021.count_0;
  const mild_2021 = counts_2021.count_1;
  const moderate_2021 = counts_2021.count_2;
  const severe_2021 = counts_2021.count_3;

  const no_drought_2022 = counts_2022.count_0;
  const mild_2022 = counts_2022.count_1;
  const moderate_2022 = counts_2022.count_2;
  const severe_2022 = counts_2022.count_3;

  // cropping intensity = cropped_area / total cropable area
  const single_kharif_cropped_area_2016 = Number(
    sessionStorage.getItem("single_kha")
  );
  const single_kharif_cropped_area_2017 = Number(
    sessionStorage.getItem("single_k_1")
  );
  const single_kharif_cropped_area_2018 = Number(
    sessionStorage.getItem("single_k_2")
  );
  const single_kharif_cropped_area_2019 = Number(
    sessionStorage.getItem("single_k_3")
  );
  const single_kharif_cropped_area_2020 = Number(
    sessionStorage.getItem("single_k_4")
  );
  const single_kharif_cropped_area_2021 = Number(
    sessionStorage.getItem("single_k_5")
  );
  const single_kharif_cropped_area_2022 = Number(
    sessionStorage.getItem("single_k_6")
  );
  const single_non_kharif_cropped_area_2016 = Number(
    sessionStorage.getItem("single_non")
  );
  const single_non_kharif_cropped_area_2017 = Number(
    sessionStorage.getItem("single_n_1")
  );
  const single_non_kharif_cropped_area_2018 = Number(
    sessionStorage.getItem("single_n_2")
  );
  const single_non_kharif_cropped_area_2019 = Number(
    sessionStorage.getItem("single_n_3")
  );
  const single_non_kharif_cropped_area_2020 = Number(
    sessionStorage.getItem("single_n_4")
  );
  const single_non_kharif_cropped_area_2021 = Number(
    sessionStorage.getItem("single_n_5")
  );
  const single_non_kharif_cropped_area_2022 = Number(
    sessionStorage.getItem("single_n_6")
  );
  const double_cropped_area_2016 = Number(sessionStorage.getItem("doubly_cro"));
  const double_cropped_area_2017 = Number(sessionStorage.getItem("doubly_c_1"));
  const double_cropped_area_2018 = Number(sessionStorage.getItem("doubly_c_2"));
  const double_cropped_area_2019 = Number(sessionStorage.getItem("doubly_c_3"));
  const double_cropped_area_2020 = Number(sessionStorage.getItem("doubly_c_4"));
  const double_cropped_area_2021 = Number(sessionStorage.getItem("doubly_c_5"));
  const double_cropped_area_2022 = Number(sessionStorage.getItem("doubly_c_6"));
  const triple_cropped_area_2016 = Number(sessionStorage.getItem("triply_cro"));
  const triple_cropped_area_2017 = Number(sessionStorage.getItem("triply_c_1"));
  const triple_cropped_area_2018 = Number(sessionStorage.getItem("triply_c_2"));
  const triple_cropped_area_2019 = Number(sessionStorage.getItem("triply_c_3"));
  const triple_cropped_area_2020 = Number(sessionStorage.getItem("triply_c_4"));
  const triple_cropped_area_2021 = Number(sessionStorage.getItem("triply_c_5"));
  const triple_cropped_area_2022 = Number(sessionStorage.getItem("triply_c_6"));

  const total_cropable_area_2016_2022 = Number(
    sessionStorage.getItem("total_crop")
  );
  const cropping_intensity_2016 = Number(sessionStorage.getItem("cropping_i"));
  const cropping_intensity_2017 = Number(sessionStorage.getItem("cropping_1"));
  const cropping_intensity_2018 = Number(sessionStorage.getItem("cropping_2"));
  const cropping_intensity_2019 = Number(sessionStorage.getItem("cropping_3"));
  const cropping_intensity_2020 = Number(sessionStorage.getItem("cropping_4"));
  const cropping_intensity_2021 = Number(sessionStorage.getItem("cropping_5"));
  const cropping_intensity_2022 = Number(sessionStorage.getItem("cropping_6"));

  const triple_cropping_2016 =
    (triple_cropped_area_2016 / total_cropable_area_2016_2022) * 100;
  const triple_cropping_2017 =
    (triple_cropped_area_2017 / total_cropable_area_2016_2022) * 100;
  const triple_cropping_2018 =
    (triple_cropped_area_2018 / total_cropable_area_2016_2022) * 100;
  const triple_cropping_2019 =
    (triple_cropped_area_2019 / total_cropable_area_2016_2022) * 100;
  const triple_cropping_2020 =
    (triple_cropped_area_2020 / total_cropable_area_2016_2022) * 100;
  const triple_cropping_2021 =
    (triple_cropped_area_2021 / total_cropable_area_2016_2022) * 100;
  const triple_cropping_2022 =
    (triple_cropped_area_2022 / total_cropable_area_2016_2022) * 100;

  const double_cropping_2016 =
    (double_cropped_area_2016 / total_cropable_area_2016_2022) * 100;
  const double_cropping_2017 =
    (double_cropped_area_2017 / total_cropable_area_2016_2022) * 100;
  const double_cropping_2018 =
    (double_cropped_area_2018 / total_cropable_area_2016_2022) * 100;
  const double_cropping_2019 =
    (double_cropped_area_2019 / total_cropable_area_2016_2022) * 100;
  const double_cropping_2020 =
    (double_cropped_area_2020 / total_cropable_area_2016_2022) * 100;
  const double_cropping_2021 =
    (double_cropped_area_2021 / total_cropable_area_2016_2022) * 100;
  const double_cropping_2022 =
    (double_cropped_area_2022 / total_cropable_area_2016_2022) * 100;

  const single_kharif_cropping_2016 =
    (single_kharif_cropped_area_2016 / total_cropable_area_2016_2022) * 100;
  const single_kharif_cropping_2017 =
    (single_kharif_cropped_area_2017 / total_cropable_area_2016_2022) * 100;
  const single_kharif_cropping_2018 =
    (single_kharif_cropped_area_2018 / total_cropable_area_2016_2022) * 100;
  const single_kharif_cropping_2019 =
    (single_kharif_cropped_area_2019 / total_cropable_area_2016_2022) * 100;
  const single_kharif_cropping_2020 =
    (single_kharif_cropped_area_2020 / total_cropable_area_2016_2022) * 100;
  const single_kharif_cropping_2021 =
    (single_kharif_cropped_area_2021 / total_cropable_area_2016_2022) * 100;
  const single_kharif_cropping_2022 =
    (single_kharif_cropped_area_2022 / total_cropable_area_2016_2022) * 100;

  const single_non_kharif_cropping_2016 =
    (single_non_kharif_cropped_area_2016 / total_cropable_area_2016_2022) * 100;
  const single_non_kharif_cropping_2017 =
    (single_non_kharif_cropped_area_2017 / total_cropable_area_2016_2022) * 100;
  const single_non_kharif_cropping_2018 =
    (single_non_kharif_cropped_area_2018 / total_cropable_area_2016_2022) * 100;
  const single_non_kharif_cropping_2019 =
    (single_non_kharif_cropped_area_2019 / total_cropable_area_2016_2022) * 100;
  const single_non_kharif_cropping_2020 =
    (single_non_kharif_cropped_area_2020 / total_cropable_area_2016_2022) * 100;
  const single_non_kharif_cropping_2021 =
    (single_non_kharif_cropped_area_2021 / total_cropable_area_2016_2022) * 100;
  const single_non_kharif_cropping_2022 =
    (single_non_kharif_cropped_area_2022 / total_cropable_area_2016_2022) * 100;

  const single_cropping_2016 =
    single_kharif_cropping_2016 + single_non_kharif_cropping_2016;
  const single_cropping_2017 =
    single_kharif_cropping_2017 + single_non_kharif_cropping_2017;
  const single_cropping_2018 =
    single_kharif_cropping_2018 + single_non_kharif_cropping_2018;
  const single_cropping_2019 =
    single_kharif_cropping_2019 + single_non_kharif_cropping_2019;
  const single_cropping_2020 =
    single_kharif_cropping_2020 + single_non_kharif_cropping_2020;
  const single_cropping_2021 =
    single_kharif_cropping_2021 + single_non_kharif_cropping_2021;
  const single_cropping_2022 =
    single_kharif_cropping_2022 + single_non_kharif_cropping_2022;

  const uncropped_area_2016 =
    100 -
    (single_kharif_cropping_2016 +
      single_non_kharif_cropping_2016 +
      double_cropping_2016 +
      triple_cropping_2016);
  const uncropped_area_2017 =
    100 -
    (single_kharif_cropping_2017 +
      single_non_kharif_cropping_2017 +
      double_cropping_2017 +
      triple_cropping_2017);
  const uncropped_area_2018 =
    100 -
    (single_kharif_cropping_2018 +
      single_non_kharif_cropping_2018 +
      double_cropping_2018 +
      triple_cropping_2018);
  const uncropped_area_2019 =
    100 -
    (single_kharif_cropping_2019 +
      single_non_kharif_cropping_2019 +
      double_cropping_2019 +
      triple_cropping_2019);
  const uncropped_area_2020 =
    100 -
    (single_kharif_cropping_2020 +
      single_non_kharif_cropping_2020 +
      double_cropping_2020 +
      triple_cropping_2020);
  const uncropped_area_2021 =
    100 -
    (single_kharif_cropping_2021 +
      single_non_kharif_cropping_2021 +
      double_cropping_2021 +
      triple_cropping_2021);
  const uncropped_area_2022 =
    100 -
    (single_kharif_cropping_2022 +
      single_non_kharif_cropping_2022 +
      double_cropping_2022 +
      triple_cropping_2022);

  const droughtData = [
    {
      year: "2016",
      no_drought: no_drought_2016,
      mild: mild_2016,
      moderate: moderate_2016,
      severe: severe_2016,
    },
    {
      year: "2017",
      no_drought: no_drought_2017,
      mild: mild_2017,
      moderate: moderate_2017,
      severe: severe_2017,
    },
    {
      year: "2018",
      no_drought: no_drought_2018,
      mild: mild_2018,
      moderate: moderate_2018,
      severe: severe_2018,
    },
    {
      year: "2019",
      no_drought: no_drought_2019,
      mild: mild_2019,
      moderate: moderate_2019,
      severe: severe_2019,
    },
    {
      year: "2020",
      no_drought: no_drought_2020,
      mild: mild_2020,
      moderate: moderate_2020,
      severe: severe_2020,
    },
    {
      year: "2021",
      no_drought: no_drought_2021,
      mild: mild_2021,
      moderate: moderate_2021,
      severe: severe_2021,
    },
    {
      year: "2022",
      no_drought: no_drought_2022,
      mild: mild_2022,
      moderate: moderate_2022,
      severe: severe_2022,
    },
  ];

  const croppingPatternData = [
    {
      year: "2016",
      single: single_cropping_2016,
      double: double_cropping_2016,
      triple: triple_cropping_2016,
      uncropped: uncropped_area_2016,
    },
    {
      year: "2017",
      single: single_cropping_2017,
      double: double_cropping_2017,
      triple: triple_cropping_2017,
      uncropped: uncropped_area_2017,
    },
    {
      year: "2018",
      single: single_cropping_2018,
      double: double_cropping_2018,
      triple: triple_cropping_2018,
      uncropped: uncropped_area_2018,
    },
    {
      year: "2019",
      single: single_cropping_2019,
      double: double_cropping_2019,
      triple: triple_cropping_2019,
      uncropped: uncropped_area_2019,
    },
    {
      year: "2020",
      single: single_cropping_2020,
      double: double_cropping_2020,
      triple: triple_cropping_2020,
      uncropped: uncropped_area_2020,
    },
    {
      year: "2021",
      single: single_cropping_2021,
      double: double_cropping_2021,
      triple: triple_cropping_2021,
      uncropped: uncropped_area_2021,
    },
    {
      year: "2022",
      single: single_cropping_2022,
      double: double_cropping_2022,
      triple: triple_cropping_2022,
      uncropped: uncropped_area_2022,
    },
  ];

  const croppingIntensityData = [
    { year: "2016", value: cropping_intensity_2016 },
    { year: "2017", value: cropping_intensity_2017 },
    { year: "2018", value: cropping_intensity_2018 },
    { year: "2019", value: cropping_intensity_2019 },
    { year: "2020", value: cropping_intensity_2020 },
    { year: "2021", value: cropping_intensity_2021 },
    { year: "2022", value: cropping_intensity_2022 },
  ];

  const [selectedYear, setSelectedYear] = useState(droughtData[0].year);
  const navigate = useNavigate();

  const handleYearChange = (event, newValue) => {
    setSelectedYear(droughtData[newValue].year);
  };

  const marks = droughtData.map((entry, index) => ({
    value: index,
    label: entry.year,
  }));

  const filteredDroughtData = droughtData.filter(
    (d) => d.year === selectedYear
  );
  const filteredCroppingPatternData = croppingPatternData.filter(
    (d) => d.year === selectedYear
  );

  const twoDecimalFormatter = (number) => {
    return number.toFixed(2);
  };

  const handleAgriFeedbackClick = () => {
    // navigate("/feedback", {
    //   state: {
    //     screen_code: "feedback_agri",
    //     redirect_url: encodeURIComponent(util.generate_state_url("agri", "main_screen"))
    //   }
    // });
    let redirectState = {
      screen_code: "feedback_agri",
      redirect_url: encodeURIComponent(
        util.generate_state_url("agri", "main_screen")
      ),
      next_screen: "",
    };

    onSetState(redirectState);

    onOpenOdk();
  };

  const bodyContent = (
    <div style={barGraphContainerStyle}>
      <div style={graphAndSliderContainerStyle}>
        <h2 style={headerStyle}>Drought Frequency</h2>
        <ResponsiveContainer width="100%" height={250}>
          <BarChart
            data={filteredDroughtData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            {/* ... Rest of your BarChart code ... */}
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis
              label={{
                value: "Drought frequency (# weeks)",
                angle: -90,
                position: "Left",
              }}
            />
            <Tooltip formatter={twoDecimalFormatter} />
            <Legend align="center" verticalAlign="top" height={36} />
            <YAxis
              type="number"
              domain={[0, 100]} // Fix the Y-axis from 0 to 100
              position="left"
              tickFormatter={(value) => `${value}%`} // Optional: Add percentage formatting
            />
            <Bar dataKey="no_drought" fill="#e0e1dd" name="No Drought" />
            <Bar dataKey="mild" fill="#F4D03F" name="Mild" />
            <Bar dataKey="moderate" fill="#EB984E" name="Moderate" />
            <Bar dataKey="severe" fill="#E74C3C" name="Severe" />
            <Dot cx={2016} cy={75} r={4} />
          </BarChart>
        </ResponsiveContainer>
        <div style={textContainerStyle}>
          <p>{t("info_agri_modal_1")}</p>
        </div>
        <ThemeProvider theme={theme}>
          <CustomSlider
            handleYearChange={handleYearChange}
            marks={marks}
            selectedYear={selectedYear}
          />
        </ThemeProvider>

        <div style={{ width: "100%", height: "250px", marginTop: "20px" }}>
          <h2 style={headerStyle}>Cropping Intensity</h2>
          <ResponsiveContainer width="100%" height={250}>
            <BarChart
              data={filteredCroppingPatternData}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis
                label={{
                  value: "Cropping Patterns",
                  angle: -90,
                  position: "Left",
                }}
              />
              <Tooltip formatter={twoDecimalFormatter} />
              {/* <Legend verticalAlign="bottom" height={36} /> */}
              <Legend
                align="center"
                verticalAlign="bottom"
                layout="horizontal"
                wrapperStyle={{ lineHeight: "20px" }}
              />
              <Bar
                dataKey="single"
                stackId="a"
                fill="#57ad2b"
                name="Single Cropping"
              />
              <Bar
                dataKey="double"
                stackId="a"
                fill="#e68600"
                name="Double Cropping"
              />
              <Bar
                dataKey="triple"
                stackId="a"
                fill="#b3561d"
                name="Triple Cropping"
              />
              <Bar
                dataKey="uncropped"
                stackId="a"
                fill="#A9A9A9"
                name="Uncropped Area"
              />
            </BarChart>
          </ResponsiveContainer>

          <ResponsiveContainer width="100%" height={250}>
            <LineChart
              data={croppingIntensityData}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis
                label={{
                  value: "Cropping Intensity",
                  angle: -90,
                  position: "Left",
                }}
              />
              <Tooltip formatter={twoDecimalFormatter} />
              <Line
                type="monotone"
                dataKey="value"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
          <div style={textContainerStyle}>
            <p>{t("info_agri_modal_2")}</p>
          </div>
          {/* Feedback button at the end */}
          <div style={centerStyle}>
            <Button
              label={"Provide Feedback"}
              onClick={handleAgriFeedbackClick}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={"Agriculture Analysis Graph"}
      body={bodyContent}
    />
  );
};

const CustomSlider = ({ handleYearChange, marks, selectedYear }) => {
  return (
    <Box
      sx={{
        width: 300,
        margin: "auto",
        backgroundColor: "#D6D4C8",
        borderRadius: "20px",
        padding: "20px",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Slider
        aria-label="Years"
        defaultValue={6}
        valueLabelDisplay="auto"
        step={null}
        marks={marks}
        max={marks.length - 1}
        onChange={handleYearChange}
        color="primary"
        sx={{
          color: "#795664",
          "& .MuiSlider-thumb": {
            borderRadius: "50%",
            width: 20,
            height: 20,
            backgroundColor: "#795664",
            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
            "&:hover": {
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.3)",
            },
          },
          "& .MuiSlider-rail": {
            height: 6,
            borderRadius: 4,
            backgroundColor: "#795664",
          },
          "& .MuiSlider-track": {
            height: 6,
            borderRadius: 4,
            backgroundColor: "#795664",
          },
        }}
      />
    </Box>
  );
};

export default AgricultureModal;

const graphAndSliderContainerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  justifyContent: "left",
  padding: "15px",
};

const barGraphContainerStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "left",
  alignItems: "left",
  overflowY: "auto",
  maxHeight: "80vh",
  marginTop: "25px",
};

const textContainerStyle = {
  textAlign: "left",
  padding: "0px",
  fontSize: "13px",
  // marginBottom: '10px' // Add some space before the graphs
};

const centerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "20px",
  padding: "20px",
  marginBottom: "20px",
};

const headerStyle = {
  textAlign: "left",
  width: "100%",
  marginBottom: "20px",
};
