import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { Button} from 'react-bootstrap';




const countries = {
  France: ["Paris", "Marseille", "Lille", "Lyon"],
  Usa: ["New York", "San Francisco", "Austin", "Dallas"]
};

const blockoptions = []

const options = [
  { value: 'jamui', label: 'Jamui', 'blocks': [{ value: 'Ratanpur', label: 'Ratanpur' }, { value: 'Kheira', label: 'Kheira' }] },
  { value: 'Khuti', label: 'Ranchi', 'blocks': [{ value: 'Angara', label: 'Angara' }, { value: 'silli', label: 'silli' }] },
  { value: 'dumka', label: 'HazariBagh', 'blocks': [{ value: 'latehar', label: 'Latehar' }, { value: 'Muri', label: 'Muri' }] }
]





const DistDropdown = () => {
  const [countryData, setCountryData] = useState(["Usa"]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [userChoice, setUserChoice] = useState("");
  const [isSearchable, setIsSearchable] = useState(true);
  console.log(userChoice)

  const getBlockFromDistrict = district => {
    console.log(district.blocks)
    return district.blocks
  }

  const checkInsertInArray = newCountry => {
    let findStatus = countryData.find(x => {
      return x === newCountry;
    });
    if (!findStatus) {
      setCountryData([...countryData, newCountry]);
    }
  };

  const countryChange = event => {
    if (event.target.value) {
      setSelectedCountry(event.target.value);
    }
  };

  const getSelectedDistrict = alldistrict =>{
    console.log(alldistrict)
    return alldistrict

  };

  const Submit = buttonfire => {
    console.log('button fure')
    return (<div>Test</div>)
  };

  
 

  useEffect(() => {
    Object.keys(countries).forEach(country => {
      checkInsertInArray(country);
    });
  });

  return (
    <div className="center">
      

      <Select options={options}
      onChange={(chioce) => setUserChoice(chioce)}
      placeholder={<div>Please Select District</div>}
      isSearchable={isSearchable}
      />
      <br></br>
      <br></br>
      <Select options={getBlockFromDistrict(userChoice)}
      placeholder={<div>Please Select Block</div>}
      isSearchable={isSearchable}
      />
   <br></br>
   <br></br>

<div className="mb-10">
        <Button  onClick={Submit} placeholder="Submit">Submit</Button>
          
        </div>

      
    </div>
  );
};

export default DistDropdown;
